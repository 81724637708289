const util = require('../util');
const dialog = require('../dialog');
const validator = require('../validator');

/**
 * @private
 * @function
 * @description Initializes the change account and change plan dialog forms
 */
function initializeInstallmentsDialog() {
    validator.init();
    // If planCodes returned in html, store them in localStorage
    const planCodesResponse = $('#installmentsDgPlanCodesResponse');
    if (planCodesResponse.val()) {
        localStorage.setItem('planCodes', planCodesResponse.val());
    }

    $('.cancel').click((e) => {
        e.preventDefault();
        dialog.close();
    });

    const $installmentsDg = $('#InstallmentsDialog');
    const $ajaxBtn = $installmentsDg.find('.button.ajaxHandled, a.ajaxHandled');
    $($ajaxBtn).on('click', (event) => {
        event.preventDefault();
        const options = {
            url: util.ajaxUrl($(event.currentTarget).attr('href')),
        };
        $.ajax(options).done((data) => {
            if (typeof data === 'string') {
                dialog.$container.html(data);
                initializeInstallmentsDialog();
            } else {
                dialog.$container.html(`<h1>${Resources.SERVER_ERROR}</h1>`);
            }
        });
    });

    const $submit = $installmentsDg.find('button[value="submit"]');
    $($submit).on('click', (event) => {
        const $triggeredSubmit = $(event.currentTarget);
        const $triggeredForm = $triggeredSubmit.parents('form');
        if (!$triggeredForm.valid()) {
            return;
        }
        event.preventDefault();
        let data = $triggeredForm.serialize();
        // add form action to data
        data += `&${$triggeredSubmit.attr('name')}=`;
        // make sure the server knows this is an ajax request
        if (data.indexOf('ajax') === -1) {
            data += '&format=ajax';
        }
        $.ajax({
            type: 'POST',
            url: $triggeredForm.attr('action'),
            data,
            success(response) {
                if (typeof response === 'object' && typeof response.planCodes === 'string') {
                    // Store planCodes in localStorage so that they are available while not authenticated
                    localStorage.setItem('planCodes', response.planCodes);
                } else if (typeof response === 'string') {
                    dialog.$container.html(response);
                    initializeInstallmentsDialog();
                }
            },
            failure() {
                dialog.$container.html(`<h1>${Resources.SERVER_ERROR}</h1>`);
            },
        });
    });
}

module.exports = {
    init() {
        if ($('.installments-dialog').length) {
            setTimeout(() => {
                const planCodes = String(localStorage.getItem('planCodes'));
                const installationsDialogIterator = parseInt(localStorage.getItem('installationsDialogIterator'), 10) || 0;
                const installmentsDgLink = $('.installments-dialog');
                const options = {
                    url: util.appendParamToURL(util.ajaxUrl(installmentsDgLink.attr('href')), 'planCodes', planCodes),
                };
                $.ajax(options).done((data) => {
                    if (typeof data === 'object' && typeof data.planCodes === 'string') {
                        // Store planCodes in localStorage so that they are available while not authenticated
                        if (data.planCodes.length > 2) {
                            localStorage.setItem('planCodes', data.planCodes);
                        }
                    } else if (installationsDialogIterator < 5) {
                        // If the dialog has been displayed 5 times ever, skip it so as not to spam
                        localStorage.setItem('installationsDialogIterator', installationsDialogIterator + 1);
                        dialog.create({
                            target: $(`#${installmentsDgLink.data('dialog')}`),
                            options: {
                                title: installmentsDgLink.data('title'),
                                width: installmentsDgLink.data('width'),
                                draggable: false,
                                open: initializeInstallmentsDialog,
                            },
                        });
                        dialog.openWithContent({
                            content: data,
                        });
                    }
                });
            }, 3000);
        }

        $('.recommendations-container .tiles-container').slick({
            speed: 300,
            dots: false,
            arrows: true,
            slide: '.grid-tile',
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: util.getViewports('md'),
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 690,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        });
        util.initDynamicCarousel('[id^="cq_recomm_slot"]', '.recommendations-container .tiles-container', 0);
    },
};
